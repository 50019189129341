import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import SidebarCategories from '../components/sidebar/Categories';
import Search from '../components/sidebar/Search';
import Archives from '../components/sidebar/Archives';
import Schedule from '../components/sidebar/Schedule';
import Shop from '../components/sidebar/Shop';
import SEO from '../components/seo';
import '../styles/app.scss';

const singleResources = ({ data }) => {
    const { wordpressWpResourcesPostType: post } = data;
    return (
        <>
            <SEO
                title={post.yoast_title}
                meta={post.yoast_meta}
                description={post.excerpt}
            />
            <HeaderMain />
            <HeroSingle pageTitle="Resources" />
            <section className="page-section">
                <div className="container blogwrapper blogarticle">
                    <div className="bloglft">
                        <div className="blogimg">
                            <img
                                src={
                                    post.featured_media.localFile
                                        .childImageSharp.fluid.src
                                }
                                alt={post.title}
                            />
                        </div>
                        <div className="bloxexc">
                            <h2
                                dangerouslySetInnerHTML={{
                                    __html: post.title,
                                }}
                            />
                        </div>
                        <img
                            className="blogline"
                            src={LineFull}
                            alt="lineful"
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.content,
                            }}
                        />
                    </div>

                    <div className="blogsidebar">
                        <SidebarCategories />
                        <Search />
                        <Archives />
                        <Schedule />
                 {/*      <Shop />   */}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
export default singleResources;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressWpResourcesPostType(id: { eq: $id }) {
            id
            title
            slug
            content
            yoast_meta {
                name
                property
            }
            yoast_title
            featured_media {
                localFile {
                    childImageSharp {
                        fluid {
                            srcWebp
                            src
                        }
                    }
                }
            }
        }
    }
`;
